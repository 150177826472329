.layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;

  img {
    margin: 2rem;
    margin-bottom: 3rem;
  }

  main {
    margin-bottom: 3rem;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 720px;
    position: relative;
    padding: 0;
    background: var(--nano-color-white);
    transition: 0.3s all ease-in-out;
    @media (max-width: 740px) {
      width: 100%;
      border-radius: 0;
      margin: -1rem;
      p {
        margin-right: 0 !important;
      }
    }
    p:global(.dodge-aside) {
      margin-right: 15rem;
    }
    @media (max-width: 400px) {
    }
    aside {
      position: absolute;
      right: -1rem;
      top: 1rem;
      width: 15rem;
      background: var(--nano-color-darkgrey);
      color: var(--nano-color-white);
      border-radius: 5px;
      padding: 1rem;
      h1 {
        font-size: 1.2rem;
      }
      p {
        margin-right: 0;
        font-size: 1rem;
      }
      a {
        color: var(--nano-color-lightblue);
      }

      @media (max-width: 740px) {
        position: initial;
        color: var(--nano-color-darkgrey);
        background: none;
        margin: initial;
        width: 100%;
        padding: 0;
        p {
          margin-right: 0 !important;
        }
      }
    }
  }
}
