.actionbuttonbar {
  display: flex;
  position: sticky;
  bottom: -3px;
  background: var(--nano-color-white);
  margin-top: 0;
  padding: 1rem 2rem;
  margin-bottom: 0;
  z-index: 1;
  border-radius: 0 0 3px 3px;

  transition: 0.3s all;

  &.isstuck {
    background: var(--nano-color-lightgrey);
  }
}
