body {
  background: white;
}

nano-input,
nano-select,
p,
nano-checkbox-group {
  max-width: 100%;
  &.width-10 {
    width: 10rem !important;
  }

  &.width-15 {
    width: 15rem !important;
  }

  &.width-20 {
    width: 20rem !important;
  }

  &.width-25 {
    width: 25rem;
  }

  &.width-30 {
    width: 30rem;
  }

  &.width-35 {
    width: 35rem;
  }

  &.width-40 {
    width: 40rem;
  }

  &.width-inner-15 {
    width: 100%;
    .form-ctrl__wrapper {
      flex-basis: 15rem !important;
      flex-grow: 1 !important;
    }
  }
}

form {
  display: flex;
  flex-direction: column;
}

:root {
  --nano-base-font-size: 14px;
}

body {
  position: absolute;
  width: 100%;
  background: url(../public/assets/background.webp) no-repeat fixed;
  background-size: cover;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

footer {
  background: var(--nano-color-black);
  color: var(--nano-color-white);
  padding: 2rem;
  padding-top: 1rem;
  font-size: 0.85rem;
  line-height: 1.6;
  margin-top: auto;
  hr {
    margin-bottom: 1rem;
  }
}

.when-gt-mid {
  @media (max-width: 740px) {
    display: none;
  }
}

.when-lt-mid {
  @media (min-width: 740px) {
    display: none;
  }
}

button.search-option {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  background-color: transparent;
  width: 100%;
  border-bottom: 1px solid #dddddd;
  &:first-of-type {
    border-top: 1px solid #dddddd;
  }
  padding: 0.5rem;
  text-align: left;
  &:hover {
    background-color: #efefef;
    .option__title {
      text-decoration: none;
    }
  }
  .option__icon {
    margin: 0.5rem;
  }
  .option__title {
    color: #007495;
    text-decoration: underline;
    font-weight: bold;
    padding-top: 0.5rem;
    padding-bottom: 0.25rem;
  }

  nano-icon {
    background: linear-gradient(348deg, #67a2ad, #317d93);
    padding: 0.5rem;
    margin-right: 0.75rem;
    border-radius: 0.25rem;
    color: white;
  }
}

.button-as-link {
  text-decoration: underline;
  color: #007495;
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  background: none;
  appearance: none;
  box-shadow: none;
  font-size: 0.8rem;
  &:hover,
  &:focus {
    box-shadow: none !important;
  }
}
