.container {
  z-index: 999999;
  position: fixed;
  inset: -2rem;
  backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  --spinner-scale: 4rem;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s opacity ease-in-out;
  &.show {
    opacity: 1;
    pointer-events: all;
    transition: 0.05s opacity ease-in-out;
  }
}
