article.crumbs {
  color: var(--nano-color-white);
  display: flex;
  flex-wrap: nowrap;
  max-height: 3rem;
  gap: 1rem;
  opacity: 1;
  margin-top: 0;
  margin-bottom: 2rem;
  font-size: 0.9rem;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  section {
    margin: 0 1rem;
    padding: 0.5rem 0.5rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 00;
    border-bottom: 2px solid transparent;
    transition: border-bottom-color 0.5s ease-in-out;
    &.active {
      border-bottom-color: var(--nano-color-white);
    }
  }
  transition: 0.3s all ease-in-out;
  @media (max-width: 700px) {
    max-height: 0;
    opacity: 0;
    padding: 0;
    margin: 0;
  }
}
